import React, { useEffect, useState } from 'react';
import { AudioPostProps } from './type';
import { toastError } from '../../utils/error.handler';
import Card from './Card';

// POST - https://cb52oym7v3.execute-api.eu-west-1.amazonaws.com/prod/posts
// GET - https://cb52oym7v3.execute-api.eu-west-1.amazonaws.com/prod/posts
// POST - https://cb52oym7v3.execute-api.eu-west-1.amazonaws.com/prod/upload

const lambdaUrl = 'https://cb52oym7v3.execute-api.eu-west-1.amazonaws.com/prod/posts';
const PostsContainer: React.FC = () => {
  const [posts, setPosts] = useState<AudioPostProps[]>([]);

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  // Fetch posts from the lambda 
  const fetchBlogPosts = async () => {
    try {
      const response = await fetch(lambdaUrl);
      const data = await response.json();
      setPosts((p) => data);
    } catch (error) {
      console.error("Catched Error : ", error);
      toastError('Error fetching posts');
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {posts.map((post) => (
        <Card key={post._id} blogPost={post}
        />
      ))}
    </div>
  );
};

export default PostsContainer;
