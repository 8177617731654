const ConnectionStatusComponent: React.FC<{ isConnected: boolean, disconnect: () => void }> = ({ isConnected, disconnect }) => {
    return (
        <div className='p-4'>
            Status : {
                // eslint-disable-next-line no-mixed-operators
                isConnected && 
                    <>
                        <div className='w-4 h-4 rounded-xl bg-green-500 inline-block'> </div>
                        <div className="inline-block ml-20 cursor-pointer p-2 bg-red-500 rounded-md"> <span onClick={disconnect}> Disconnect </span> </div>
                    </>
                    // eslint-disable-next-line no-mixed-operators
                    ||
                    <div className='w-4 h-4 rounded-xl bg-red-500 inline-block'> </div>
            }
        </div>
    )
}

export default ConnectionStatusComponent;