import PostsContainer from './components/BlogPost/PostsContainer';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Chat } from './components/Chat/Chat';

import { Router, Route, Switch } from "react-router-dom";
import history from "./utils/history";
import { Menu } from './components/Menu/Menu';


const App = () => {
  return (
    <Router history={history}>
      <div className=" bg-gray-100 font-mono">
        <Menu />
        <main className="p-4">
          <Switch>
            <Route path="/" exact component={PostsContainer} />
            <Route path="/chat" exact component={Chat} />
          </Switch>
        </main>
        <ToastContainer />
      </div>
    </Router>
  );
};

export default App;