import { AudioPostProps } from "./type";

interface CardProps{
    blogPost: AudioPostProps;
}

const Card = (props: CardProps) => {
    const {blogPost: 
        {
            title,
            picture: { fullUrl },
            text,
            previewAudio
        }
    } = props;

    return (
        <>
            {/* TODO : Bring the Flexbox magic here  */}
            <div className="bg-slate-200 p-4 rounded-md">
                <div className="text-1xl font-semibold p-2">
                    <h2>{title}</h2>
                </div>
                <div className="p-2">
                    <img className="rounded-md" src={fullUrl} alt="" />
                </div>
                <div className="text-sm p-2">
                    <p>{text}</p>
                </div>
                <audio controls className="p-2 sm:w-full">
                    <source src={previewAudio} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            </div>  
        </>
    );
}

export default Card;