import React, { useState, SetStateAction, useRef, useEffect } from 'react';
import { dateBeautify } from '../../utils/utils';
import { toastError } from '../../utils/error.handler';
import ConnectionStatusComponent from './ConnexionStatus';
import { Loading } from '../Loading/Loading';

// let socket: WebSocket = new WebSocket('ws://localhost:3001/prod'); // -> Local
let socket = new WebSocket('wss://ky1j0ockj2.execute-api.eu-west-1.amazonaws.com/prod'); // -> Prod 

// Chat React functionnal component
export const Chat: React.FC = () => {
    const [ logsMessages, setLogsMessages ]: [{username: string, message: string, timestamp: string}[], SetStateAction<any>]= useState([]);
    const [ isConnected, setIsConnected ] = useState(false);
    const [ username, setUsername ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);

    const scrollableDivRef = useRef<HTMLDivElement | null>(null);
    
    if(socket) {
        socket.onopen = () => {
            console.log('WebSocket connection established');
            // kep the connection alive by calling the lambda function every 5 seconds
            setInterval(() => {
                socket.send(
                    JSON.stringify({
                        action: 'keepConnectionAlive',
                    })
                );
            }, 60000);
        };
    
        socket.onmessage = (event) => {
            console.log({
                message: 'WebSocket message received',
                data: event.data,
            })
            webSocketEventReceiverHandler(event);
        };
    
        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
        };
    
        socket.onclose = () => {
            setIsConnected(false);
        };
    };

    const sendMessageAndCleanMessageInput: any = (e: KeyboardEvent) => {
        console.log(e.key, ' key pressed'); 
        if(e.key === 'Enter') {
            socket.send(
                JSON.stringify({
                    action: 'sendMessage',
                    message,
                })
            );
        }
    };

    const IdentifyAndSetUserConnected: any = (e: KeyboardEvent) => {
        if(e.key === 'Enter') {
            socket.send(
                JSON.stringify({
                    action: 'identify',
                    username,
                })
            );
            setIsLoading(true);
        }
    };

    const disconnect = () => {
        socket.send(
            JSON.stringify({
                action: 'manualDisconnect',
            })
        );
        setIsLoading(true);
    };

    useEffect(() => {
        if (scrollableDivRef.current) {
          // Scroll to the bottom of the div
          scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
        }
      }, [logsMessages]); // Empty dependency

    const webSocketEventReceiverHandler: any = (event: any): void => {
        const eventParsed = JSON.parse(event.data)
        const eventName = eventParsed.action
        let latestLogs: {username: string, message: string, timestamp: string}[] = [];
        switch (eventName) {
            case 'identify':
                latestLogs = eventParsed.sortedLatestLogs;
                if(eventParsed.status === 'success') {
                    setIsConnected(true);
                    setLogsMessages(latestLogs);
                    setIsLoading(false);
                } else {
                    toastError('An error occured while trying to connect');
                    setIsConnected(false);
                    setIsLoading(false);
                    setUsername('');
                }
                break;
            
            case 'sendMessage':
                latestLogs = eventParsed.sortedLatestLogs;
                if(eventParsed.status === 'success') {
                    setMessage('');
                    setLogsMessages(latestLogs);
                } else {
                    toastError('An error occured while sending message');
                }
                break;
            
            case 'manualDisconnect':
                if(eventParsed.status === 'success') {
                    setIsConnected(false);
                    setUsername('');
                    setLogsMessages([]);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    setIsConnected(false);
                    setLogsMessages([]);
                    setUsername('');
                    toastError('An error occured while trying to disconnect');
                }
                break;

            default:
                break;
        }
    };

    return (
        // Add a border with radius to the div and add a transparent gray background
        <div
            className='mt-10 mb-10' 
            style={
                {
                    border: '1px solid gray',
                    backgroundColor: 'rgba(0,0,0,0.1)',
                    borderRadius: '6px',
                }
            }>
            <ConnectionStatusComponent isConnected={isConnected} disconnect={disconnect}/>
            {isConnected ? 
                (   isLoading? (
                        <Loading />
                        ) :
                        <div className='m-10'>
                            {/* Display the logsMessages via a map */}
                            <div
                                ref={scrollableDivRef} 
                                style={
                                {
                                    border: '1px solid gray',
                                    borderRadius: '6px',
                                    backgroundColor: 'rgba(0,0,0,0.1)',
                                    padding: '8px',
                                    maxHeight: '300px',
                                    overflowY: 'scroll',
                                    scrollBehavior: 'smooth',

                                }
                            }>
                                <h2>Messages : </h2>
                                <ul>
                                {logsMessages.map((message, index) => (
                                    <li key={index}>
                                        <i> {dateBeautify(message.timestamp)} </i> - <b>{message.username} </b> : {message.message}
                                    </li>
                                ))}
                                </ul>
                            </div>
                            <div className='mt-4'>
                                <input
                                    type='text' 
                                    placeholder='Type a message' 
                                    style={{padding: '8px', borderRadius: '6px', border: '1px solid #ccc', marginRight: '4px'}} 
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyPress={ sendMessageAndCleanMessageInput }
                                />
                                <button 
                                    style={{border: "1px", backgroundColor: 'orchid', padding: '8px', borderRadius: '6px'}}
                                    onClick={ () => {
                                        socket.send(
                                            JSON.stringify({
                                                action: 'sendMessage',
                                                message,
                                            })
                                        );
                                    } }>
                                        Send message
                                </button>
                            </div>
                        </div>
                ) : (
                    !isConnected && isLoading ? (
                        <Loading />
                    ) :
                    <div className='m-10'>
                        {/* input text in which you can type */}
                        <input
                            type='text'
                            placeholder='Type your username'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            onKeyPress={ IdentifyAndSetUserConnected }
                            style={{padding: '8px', borderRadius: '6px', border: '1px solid #ccc'}} />
                        <button className='ml-2' onClick={ () => {
                            socket.send(
                                JSON.stringify({
                                    action: 'identify',
                                    username,
                                })
                            );
                            setIsLoading(true);
                        } }>Connect</button>
                    </div>
                )
            }
        </div>
    );
};