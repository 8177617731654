import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

export const Menu = () => {
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
        error
      } = useAuth0();

    const logoutWithRedirect = () => {
        return logout({
            logoutParams: {
              returnTo: window.location.origin,
            }
        });
    }
    
    if (error) {
        return <div> Oops... {error.message} </div>;
    }
    
    return(
        <header className="bg-slate-500 text-white text-center py-40 
          bg-[url('https://i.pinimg.com/736x/4e/8b/c1/4e8bc132a4fda923f3f6c9651a222941.jpg')] 
          bg-no-repeat bg-cover border-b-4">
          <h1 className="text-6xl font-bold">Otpo</h1>
          <nav className="flex justify-center mt-4">
            <ul className="flex space-x-4 underline">
                <Link className="hover:bg-red-500" to="/">Blog Post</Link>
                <Link className="hover:bg-red-500" to="/chat">Chat</Link>
            </ul>
          </nav>
          {!isAuthenticated && (
            <div className="mt-4 hover:bg-red-500">
              <button
                className="underline"
                onClick={() => loginWithRedirect()}
              >
                Log in
              </button>
            </div>
          )}
          {isAuthenticated && (
            <div className="mt-4">
                <div className="absolute right-20">
                    {`Hello ${user?.name}`}
                    <div className="ml-10">
                        <img
                            src={user?.picture}
                            alt="Profile"
                            className="w-10 h-10 rounded-full"
                        />
                    </div>
                </div>
                
                <button
                    className="underline ml-6 hover:bg-red-500"
                    onClick={() => logoutWithRedirect()}
                >
                    Log out
                </button>
            </div>
          )}
        </header>
    )
};